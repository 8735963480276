<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center class="login">
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.email"
                :rules="rules.email"
                prepend-icon="mdi-email"
                label="Email"
                required
                solo></v-text-field>
              <v-text-field
                v-model="user.password"
                :rules="rules.password"
                prepend-icon="mdi-lock"
                label="Password"
                type="password"
                @keyup.enter="submit"
                required
                solo></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="clear">clear</v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="!valid" @click="submit" color="primary"> submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { act } from '@/store/constants'
import path from '../../path'

export default {
  data () {
    return {
      rules: {
        email: [
          v => !!v || 'E-mail is required',
          v => /\S+@\S+\.\S+/.test(v) || 'E-mail must be valid'
        ],
        password: [
          v => !!v || 'Password is required',
          v => (v && v.length >= 8) || 'Password must be more than 8 characters'
        ]
      },
      user: {
        email: '',
        password: ''
      },
      valid: true
    }
  },
  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch(act.LOGIN, this.user)
          .then(() => {
            if (this.$route.query.next) {
              // we wanted to reach a specific route, so let's redirect toward it
              this.$router.push(this.$route.query.next)
            } else {
              // we were on the login page, let's open the account view
              this.$router.push(path.ACCOUNT)
            }
          })
          .catch(error => {
            console.log('Error while login: ', error.message, '. Logging out.')
            this.$store.dispatch(act.LOGOUT)
          })
      }
    },
    clear () {
      this.$refs.form.reset()
    }
  }
}
</script>

<style>
  .login {
    background-image: url('../../assets/logo.png');

    /* Center and scale the image nicely */
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
  }
</style>
